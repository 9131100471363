.logIn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 95%;
    gap: 20px;
  
    >form{
      width: 30%;
    }
  }