:root {
  --color-primary: #043459;
  --color-secondary-light: #FF8700;
  --color-green: #014D54;
  --color-green-light: #25595E;
  --color-secondary: #8A4900;
  --color-secondary-lighter: #E2D5C6;
  --color-white: #fdfdfd;
  --background-main: #f0f2f5;

}