.fullBoard{
    display: flex;
    flex-flow: row nowrap;
    overflow-x: scroll;

    .column {
        margin-right: 50px;
    }
}

.board{
    &_header{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-bottom: 15px;
        margin-top: -15px;
    }
    &_create-btn{
        background-color: #808A91;
        border: none;
        color: #EFF0F2;
        height: 48px;
        &:hover{
            background-color: #F0E9E2;
            color: #808A91;
        }
    }
}