.form-profile {
  .form-label label {
    font-weight: 700;
  }

  h3.success-message {
    text-align: center;
    color: var(--color-green);
  }

  .btn {
    min-width: 250px;
  }
  .btn-create-inverse {
    margin-left: 20px;
  }

  .btn-reset {
    margin-left: 20px;
    margin-top: 20px;
  }

  @media screen and (min-width: 991px){
    .btn-create-inverse {
      margin-left: 0;
    }
  }
}

