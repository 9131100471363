header {
    width: 100%;
    display: flex;
    flex-flow: row;
    z-index: 1;

    .ant-menu-title-content {
        color: var(--color-primary);
        text-decoration: none;
        font-size: 20px;
        font-weight: 700;
    }

    .ant-menu-item a:hover {
        color: var(--color-primary);
    }

    .ant-menu-item-icon {
        color: var(--color-primary);
        &:hover,
        &:focus {
            color: var(--color-primary);

            &:after {
                border-color: transparent;
            }
        }
    }

    .ant-menu .ant-menu-overflow-item {
        &.ant-menu-item {
            &.ant-menu-item-selected:after,
            &:hover:after, &:focus:after {
                border-bottom: transparent;
            }
        }
        &.ant-menu-submenu {
            &:hover:after, &:focus:after {
                border-bottom: transparent;
            }
        }
    }

    .logo {
        float: left;
        width: 120px;
        height: 31px;
        margin: 16px 24px 16px 0;
        background: rgba(255, 255, 255, 0.2);
    }

    .nav-left {
        width: 50%;
    }

    .nav-right {
        width: 40%;
        justify-content: end;
    }

    .ant-menu-horizontal.ant-menu-light {
        border: none;
        background: transparent;
    }

    .ant-btn.ant-btn-default.btn-create {
        margin: auto;
        min-width: fit-content;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        min-height: 40px;
    }

    .ant-switch-checked {
        background: var(--color-primary);
    }
    .ant-image-mask:hover {
        opacity: 0;
    }
}

.ant-layout-header {
    //background: #fff;
}

.ant-input-group-wrapper {
    &.ant-input-search {
        width: 30%;
        display: flex;
        align-items: center;
    }
}

.ant-menu-item .anticon {
    padding-right: 5px;
    vertical-align: initial;
}
