html, body {
  font-family: Roboto, sans-serif;
  font-size: 65%;
  background: var(--background-main);
}

#root {
  height: 100%;

  > div {
    height: inherit;
  }

  h4 a:hover {
    color: var(--color-secondary);
    text-decoration: underline;
  }
}

/* font style */
div, p, span {
  font-size: 1.6rem;
  color: var(--color-primary);
}

h1,h2,h3,h4 {
  color: var(--color-primary);
}

h1 {
  font-size: 2.6rem;
}
h2 {
  font-size: 2.4rem;
}
h3 {
  font-size: 2rem;
}

.ant-layout-content > div {
  height: 100%;
}